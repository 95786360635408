
$('.player').YTPlayer();

$(window).scroll(function() {
	var item = $('.card-model__top-line-wrap');
	if ( item != undefined ) {
		var
			scTop = $(window).scrollTop(),
			itemTop = $('.card-model__top-line-wrap').offset().top - 50;

		if ( scTop > itemTop ) {
			$('.player').YTPPause();
		} 
		if ( scTop < itemTop ) {
			$('.player').YTPPlay();
		}
	}
});